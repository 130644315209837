import { useEffect, useState } from 'react';
import NowPlayingClientFactory, {
  NowPlayingSubscription
} from 'utils/NowPlayingFactory';

import { ScheduleData, PlaylistData, Song } from 'types/SongScheduleData';
import {
  getStreamSettings,
  StreamSettings
} from 'components/NowPlaying/StreamSettings';
import AudioType from 'models/Audio/AudioType';
import { Host } from 'utils/api/playlistScheduleFrontend';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import duration from 'dayjs/plugin/duration';

dayjs.extend(isBetween);
dayjs.extend(duration);

interface UsePlaylistScheduleProps {
  streamId: string;
  initialSongs?: Song[];
  initialHosts?: Host[];
  keepCurrent?: boolean;
}

interface PlaylistInfo {
  stream: AudioType;
  scheduleData?: ScheduleData;
  songData: Song[];
  hostData: Host[];
  currentSong?: Song;
}

const usePlaylistSchedule = ({
  streamId,
  initialSongs,
  initialHosts,
  keepCurrent
}: UsePlaylistScheduleProps): PlaylistInfo => {
  const { stream }: StreamSettings = getStreamSettings(streamId);

  const nowplayingClient = NowPlayingClientFactory({
    server:
      process.env.NEXT_PUBLIC_NOWPLAYING_SERVER ||
      'wss://nowplayingv2.publicradio.org'
  });

  const [scheduleData, setScheduleData] = useState<ScheduleData>();
  const [hostData, setHostData] = useState<Host[]>(
    initialHosts ? [...initialHosts] : []
  );
  const [songData, setSongData] = useState<Song[]>(
    initialSongs ? [...initialSongs] : []
  );
  const [currentSong, setCurrentSong] = useState<Song | undefined>(
    initialSongs ? initialSongs[0] : undefined
  );

  // initializes songData when initialSongs changes
  useEffect(() => {
    setSongData(initialSongs ? [...initialSongs] : []);
  }, [initialSongs]);

  // initializes hostData when initialHosts changes
  useEffect(() => {
    setHostData(initialHosts ? [...initialHosts] : []);
  }, [initialHosts]);

  // Registers nowplaying callbacks and ensures that we always know the currently-playing song
  useEffect(() => {
    let scheduleRegistration: NowPlayingSubscription | undefined;
    if (keepCurrent) {
      scheduleRegistration = nowplayingClient.register_callback(
        streamId,
        'schedule',
        function (data: ScheduleData) {
          setScheduleData(data);
        }
      );
    }

    const playlistRegistration = nowplayingClient.register_callback(
      streamId,
      'playlist',
      function (data: PlaylistData) {
        setCurrentSong({
          ...data.plays[0].song,
          played_at: data.plays[0].played_at
        });
        if (keepCurrent) {
          // Look at the new songs and add them to the previous songs if they aren't there
          setSongData((previousSongs) => {
            const songsToAdd: Song[] = [];
            for (const play of data.plays) {
              if (previousSongs[0]?.song_id !== play.song.song_id) {
                songsToAdd.push({ ...play.song, played_at: play.played_at });
              } else {
                break;
              }
            }
            return songsToAdd.concat(previousSongs);
          });
        }
      }
    );

    return () => {
      scheduleRegistration?.unregister();
      playlistRegistration?.unregister();
    };
  }, [streamId, keepCurrent, initialSongs, initialHosts]);

  // Updates hosts when songData changes if we have scheduleData for this service
  useEffect(() => {
    if (scheduleData && keepCurrent) {
      setHostData((previousHosts) => {
        let currentHost: Host | undefined;
        const returnHosts = [...previousHosts];
        const songsToAdd: Song[] = [];
        const previousHost = returnHosts[0];
        const schedule = scheduleData.schedule[0];

        if (previousHost?.id !== scheduleData?.schedule[0]?.se_id) {
          currentHost = {
            hostName: schedule.people[0]?.name || '',
            showName: schedule.shows[0]?.name || '',
            startTime: schedule.start_dtim,
            endTime: schedule.end_dtim,
            id: schedule.se_id,
            songs: []
          };
          returnHosts.unshift(currentHost);
        } else {
          currentHost = previousHost;
        }

        for (const song of songData) {
          const songStart = dayjs(song.played_at);
          const songDuration = dayjs.duration(`00:${song.duration}`);
          song.ended_at = songStart.add(songDuration).format();

          if (
            songStart.isBetween(currentHost.startTime, currentHost.endTime) &&
            currentHost.songs[0]?.song_id !== song.song_id
          ) {
            songsToAdd.push(song);
          } else {
            break;
          }
        }
        currentHost.songs = songsToAdd.concat(currentHost.songs);

        return returnHosts;
      });
    }
  }, [keepCurrent, songData]);

  return { stream, scheduleData, songData, hostData, currentSong };
};

export default usePlaylistSchedule;
