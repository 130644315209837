import StreamType from 'models/Streams/StreamType';
import React, { FC } from 'react';
import { Image } from '@apmg/mimas';
import ImageShape from 'config/defaultData/ImageShape';
import { ScheduleData } from 'types/SongScheduleData';
import sty from 'styles/modules/PlaylistPage.module.css';

interface Props {
  streamData?: StreamType | null;
  scheduleData?: ScheduleData | null;
  alt: string;
  hasHostImage?: boolean;
}

const StreamImage: FC<Props> = ({
  streamData,
  scheduleData,
  alt,
  hasHostImage
}) => {
  const img = scheduleData?.schedule[0]?.people[0]?.image;
  if (img?.aspect_ratios?.uncropped) {
    return (
      <img
        src={img.aspect_ratios.uncropped.instances[0].url}
        alt={alt}
        width={img.aspect_ratios.uncropped.instances[0].width}
        height={img.aspect_ratios.uncropped.instances[0].height}
        className={sty.programImg}
      />
    );
  }
  if (streamData && !hasHostImage) {
    return (
      <figure className="story-feature-image figure-full-width">
        <Image
          image={streamData?.stream.primaryVisuals.thumbnail}
          aspectRatio="uncropped"
          sizes="240px"
          loading="lazy"
          imageShape={ImageShape.uncropped}
          className={sty.programImg}
        />
      </figure>
    );
  }

  return (
    <picture>
      <source type="image/png" />
      <img
        src={'/images/default-album-art.png'}
        height="229"
        width="281"
        alt={alt}
        className={sty.programImg}
      />
    </picture>
  );
};

export default StreamImage;
