import React, { FC } from 'react';
import { Song } from 'types/SongScheduleData';
import SongItem from './SongItem';
import useSpotifyResources from 'hooks/useSpotifyResources';
import AdvertisementBanner from 'components/Advertisement/AdvertisementBanner';
import { PlaylistData } from 'types/SongScheduleData';

interface Props {
  songData: PlaylistData['plays'];
  service: string;
  day: string;
  time?: number;
  showFavorite?: boolean;
}

const Songs: FC<Props> = ({ songData, service, day, time, showFavorite }) => {
  const spotifyResourcesMap = useSpotifyResources({
    songs: songData,
    date: day,
    service
  });

  const dayArr = new Array(24).fill([]);

  if (time) {
    const songs = songData.filter((sng) => {
      return time === new Date(sng.played_at ?? '').getHours();
    });
    dayArr[time] = songs;
  } else {
    dayArr.forEach((item, index) => {
      const songs = songData.filter((sng) => {
        return index === new Date(sng.played_at ?? '').getHours();
      });
      dayArr[index] = songs;
    });
  }

  return (
    <>
      {dayArr.reverse().map((plays: Song[], i) => {
        const played_at = plays[0]?.played_at || 0;
        const playDateTime = new Date(played_at);
        const hourStart = Intl.DateTimeFormat('en', { hour: 'numeric' }).format(
          playDateTime
        );
        const hourEnd = Intl.DateTimeFormat('en', { hour: 'numeric' }).format(
          new Date(played_at).setHours(playDateTime.getHours() + 1)
        );
        return (
          <React.Fragment key={i}>
            {plays.length > 0 && (
              <h4>
                <cite className="type-hefty">
                  {hourStart}&ndash;{hourEnd}
                </cite>
              </h4>
            )}
            {plays.length > 0 && (
              <ul className="playlist tiles sixup">
                {plays.map((play) => {
                  return (
                    <React.Fragment key={play.played_at}>
                      <SongItem
                        title={play.song.title}
                        song_id={play.song.song_id}
                        played_at={play.played_at}
                        service={service}
                        spotifyResources={
                          spotifyResourcesMap && play.song.song_id
                            ? spotifyResourcesMap[play.song.song_id]
                            : undefined
                        }
                        {...play.song}
                        showFavorite={showFavorite}
                      />
                    </React.Fragment>
                  );
                })}
              </ul>
            )}
            {hourStart == '9 AM' && (
              <AdvertisementBanner
                id="midday_ad"
                adUnitPath={`/22546217694/mpr.the_current/${service}`}
                cmsId={`playlist ${service}`}
                primaryCollection={service}
                collections={[service]}
                lazyload={true}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default Songs;
