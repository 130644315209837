'use client';
import React, { FunctionComponent, useMemo } from 'react';
import { useRouter } from 'next/navigation';
import { BiChevronLeft } from '@react-icons/all-files/bi/BiChevronLeft';
import { BiChevronRight } from '@react-icons/all-files/bi/BiChevronRight';
import Songs from 'components/PlaylistSchedule/Songs';
import { HostSchedules } from 'utils/api/playlistScheduleFrontend';
import { StreamPlaylistLink } from 'components/Streams/StreamLink';
import useNav from 'hooks/useNav';
import { PlaylistData } from 'types/SongScheduleData';
import usePlaylistSchedule from 'hooks/usePlaylistSchedule';
import AudioButton from 'components/AudioButton/AudioButton';
import AdvertisementBanner from 'components/Advertisement/AdvertisementBanner';
import useSpotifyResources from 'hooks/useSpotifyResources';
import useFavorites from 'hooks/useFavorites';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import IconNowPlaying from 'components/Icon/IconNowPlaying';
import IconCalendar from 'components/Icon/IconCalendar';
import StreamType from 'models/Streams/StreamType';
import StreamImage from './StreamImage';
import sty from 'styles/modules/PlaylistPage.module.css';

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  data: PlaylistData;
  initialScheduleData?: HostSchedules;
  service: string;
  day?: string;
  time?: string;
  streamData?: StreamType | null;
}

const hasHostImage = (service: string) => {
  return ['the-current'].includes(service);
};

const getServiceName = (service: string) => {
  return (
    {
      'the-current': 'The Current',
      'radio-heartland': 'Radio Heartland®',
      'carbon-sound': 'Carbon Sound',
      'local-current': 'Local Current',
      'purple-current': 'Purple Current',
      rockthecradle: 'Rock The Cradle',
      'current-holiday': 'The Current Holiday Stream',
      'the-sirent': 'The Siren',
      'current-pride': 'The Current Pride Stream',
      ritmofonica: 'Ritmofonica',
      'current-halloween': 'Halloween Stream',
      teenagekicks: 'Teenage Kicks'
    }[service] ?? 'The Current'
  );
};

const PlaylistPage: FunctionComponent<Props> = ({
  data,
  initialScheduleData,
  service,
  day,
  time,
  streamData
}) => {
  useNav('Home');
  const router = useRouter();
  const date = dayjs.tz(day, 'America/Chicago');
  const today = dayjs.tz(undefined, 'America/Chicago');
  const previousDay = date.add(-1, 'days');
  const nextDay = date.add(1, 'days');
  const nextDayIsFuture = nextDay.diff(today.format('YYYY-MM-DD'), 'day') > 0;

  const initialSongs = useMemo(() => {
    return data.plays?.map((play) => {
      return { ...play.song, played_at: play.played_at };
    });
  }, [data]);

  const { stream, songData, hostData, scheduleData } = usePlaylistSchedule({
    streamId: service,
    initialSongs,
    initialHosts: initialScheduleData?.hosts,
    keepCurrent: day === today.format('YYYY-MM-DD')
  });

  const spotifyResourcesMap = useSpotifyResources({
    songs: songData,
    date: day || date.format('YYYY-MM-DD'),
    service
  });

  const [favorites] = useFavorites('song');
  const alt = hostData && hostData[0] ? hostData[0].hostName : 'host image';

  return (
    <>
      <main>
        <div className="container">
          <div className={sty.topSection}>
            <div className={sty.imgContainer}>
              <StreamImage
                scheduleData={scheduleData}
                streamData={streamData}
                alt={alt}
                hasHostImage={hasHostImage(service)}
              />
            </div>
            <div className={sty.topText}>
              <AdvertisementBanner
                id="div-gpt-ad-8382356-3"
                adUnitPath={`/22546217694/apmg/mpr/the-current/playlist/${service}`}
                cmsId={`playlist ${service}`}
                sizes={[[320, 25]]}
              />
              <div className={sty.header}>
                <IconNowPlaying />
                <h2>Now Playing - {getServiceName(service)}</h2>
              </div>
              <div className={sty.headerNote}>On-Air Live Broadcast</div>
              {hostData[0] && (
                <div className={`type-large ${sty.programName}`}>
                  {hostData[0].showName}
                </div>
              )}
              {hostData[0] && hostData[0].hostName && (
                <div className={sty.programHost}>
                  with {hostData[0].hostName}
                </div>
              )}
              <AudioButton
                {...stream}
                className={`btn ${sty.audioBtn}`}
                playIcon={
                  <>
                    <img
                      src="/images/icon-player-play-compact.svg"
                      alt="Play"
                    />
                    Listen Live
                  </>
                }
                pauseIcon={
                  <>
                    <img
                      src="/images/icon-player-pause-compact.svg"
                      alt="Pause"
                    />
                    Pause
                  </>
                }
              />
            </div>
          </div>
          <AdvertisementBanner
            id="div-gpt-ad-8382356-1"
            adUnitPath={`/22546217694/apmg/mpr/the-current/playlist/${service}`}
            cmsId={`playlist ${service}`}
          />
          <div className={sty.playlistHeader}>
            <div>
              <h2>On-Air Playlist</h2>
              {/* <div className={sty.underwriting}>
                Underwriting text include placement...
              </div> */}
            </div>
            <div className={sty.playlistControls}>
              <form method="GET" action={`/catalog/${service}`}>
                <input
                  type="text"
                  name="params"
                  placeholder="Song search"
                  className={sty.search}
                />
              </form>
              <form>
                <label className={`btn ${sty.dateFilter}`}>
                  <span className={sty.dateFilterCalendarIcon}>
                    <IconCalendar />
                  </span>
                  <input
                    type="date"
                    name="playlistDate"
                    max={today.format('YYYY-MM-DD')}
                    value={date.format('YYYY-MM-DD')}
                    onChange={(e) => {
                      e.preventDefault();
                      router.push(`/playlist/${service}/${e.target.value}`);
                    }}
                  />
                </label>
              </form>
            </div>
          </div>
          <div>
            <section>
              <div className="playlist-hours">
                <>
                  {data && (
                    <Songs
                      songData={data.plays}
                      service={service}
                      day={date.format('YYYY-MM-DD')}
                      time={time ? parseInt(time) : undefined}
                      showFavorite={true}
                    />
                  )}
                </>
              </div>
            </section>
            <div className={sty.playlistPagination}>
              <div>
                <StreamPlaylistLink
                  streamId={service}
                  day={previousDay.format('YYYY-MM-DD')}
                >
                  <BiChevronLeft />
                  <span className="link-nextprev type-mini">
                    {previousDay.format('dddd')}
                  </span>
                </StreamPlaylistLink>
              </div>
              {!nextDayIsFuture && (
                <div>
                  <StreamPlaylistLink
                    streamId={service}
                    day={nextDay.format('YYYY-MM-DD')}
                  >
                    <span className="link-nextprev type-mini">
                      {nextDay.format('dddd')}
                    </span>
                    <BiChevronRight />
                  </StreamPlaylistLink>
                </div>
              )}
            </div>
            <AdvertisementBanner
              id="div-gpt-ad-8382356-2"
              adUnitPath={`/22546217694/apmg/mpr/the-current/playlist/${service}`}
              cmsId={`playlist ${service}`}
            />
            {/* {router.asPath == asPathLocal || router.asPath == asPathPurple ? (
              <MNLegacy collections={mnLegacy.collection} />
            ) : (
              ' '
            )} */}
          </div>
        </div>
      </main>
    </>
  );
};

export default PlaylistPage;
